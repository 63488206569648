<template>
  <v-main class="mt-16">
    <section>
      <div class="container py-15 mt-2">
        <div class="row">
          <div class="col col-12">
            <h2 style="font-size: 25px">
              Аймгууд 
              <!-- <v-btn @click="_initAimguud">Init</v-btn> -->
            </h2>
          </div>
          <div
            class="col-sm-6 col-md-4 col-lg-3 col-12"
            v-for="res in aimguud"
            :key="res.id"
          >
            <div
              class="
                pb-2
                br-10
                o-hidden
                v-card v-sheet v-sheet--outlined
                theme--light
                elevation-0
              "
            >
              <div class="position-relative">
                <div v-if="editImage == false" style="width: 100%">
                  <v-img
                    :src="
                      res.images != null &&
                      res.images != undefined &&
                      res.images[res.images.length - 1] != null &&
                      res.images[res.images.length - 1] != undefined
                        ? res.images[0].fileUrl
                        : '/placeholder.png'
                    "
                  ></v-img>
                </div>
                <div v-if="editImage == true">
                  <ImageAdd
                    style="width: 100%"
                    :objectPath="'/restaurant/' + res.id"
                  ></ImageAdd>
                </div>
              </div>

              <div
                class="pa-4 d-flex justify-space-between align-center"
                @click="_goDetail(res.id)"
              >
                <h3 class="me-3">{{ res.name }}</h3>
                <div class="d-flex align-center card-rating">
                  <i
                    aria-hidden="true"
                    class="
                      v-icon
                      notranslate
                      me-1
                      mt-1
                      mdi mdi-star
                      theme--light
                      yellow--text
                      text--darken-2
                    "
                    style="font-size: 16px"
                  ></i>
                  <div style="line-height: 0">
                    <span class="font-weight-bold text-14">{{
                      res.rating
                    }}</span>
                    <span class="grey--text text--darken-1 text-14">/5</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </v-main>
</template>
<script>
import { db } from "@/firebaseConfig.js";
import ImageAdd from "./ImageAdd.vue";

export default {
  components: {
    ImageAdd,
  },
  data() {
    return {
      aimguud: null,
      editImage: false,
      name: null,
      price: null,
      cities2: [
        {
          name: "Улаанбаатар",
          sumuud: [
            "Хан-Уул",
            "Баянзүрх",
            "Чингэлтэй",
            "Налайх",
            "Сонгинохайрхан",
            "Баянгол",
            "Багахангай",
            "Сүхбаатар",
          ],
        },
        {
          name: "Архангай",
          sumuud: [
            "Ихтамир",
            "Өлзийт",
            "Хайрхан",
            "Эрдэнэмандал",
            "Цэнхэр",
            "Төвшрүүлэх",
            "Булган",
            "Цахир",
            "Тариат",
            "Батцэнгэл",
            "Цэцэрлэг",
            "Хангай",
            "Хашаат",
            "Чулуут",
            "Хотонт",
            "Цэцэрлэг",
            "Өгийнуур",
            "Өндөр-Улаан",
            "Жаргалант",
          ],
        },
        {
          name: "Баян-Өлгий",
          sumuud: [
            "Булган",
            "Толбо",
            "Буянт",
            "Сагсай",
            "Баяннуур",
            "Цагааннуур",
            "Цэнгэл",
            "Бугат",
            "Дэлүүн",
            "Алтай",
            "Улаанхус",
            "Ногооннуур",
            "Алтанцөгц",
          ],
        },
        {
          name: "Баянхонгор",
          sumuud: [
            "Баян-Овоо",
            "Шинэжинст",
            "Галуут",
            "Баянговь",
            "Бөмбөгөр",
            "Баянлиг",
            "Өлзийт",
            "Баянхонгор",
            "Хүрээмарал",
            "Бууцагаан",
            "Эрдэнэцогт",
            "Баянцагаан",
            "Богд",
            "Баянбулаг",
            "Жаргалант",
            "Жинст",
            "Баацагаан",
            "Баян-Өндөр",
            "Заг",
            "Гурванбулаг",
          ],
        },
        {
          name: "Булган",
          sumuud: [
            "Дашинчилэн",
            "Рашаант",
            "Могод",
            "Хутаг-Өндөр",
            "Сайхан",
            "Сэлэнгэ",
            "Бүрэгхангай",
            "Баян-Агт",
            "Тэшиг",
            "Булган",
            "Гурванбулаг",
            "Орхон",
            "Бугат",
            "Баяннуур",
            "Хангал",
            "Хишиг-Өндөр",
          ],
        },
        {
          name: "Говь-Алтай",
          sumuud: [
            "Sharga",
            "Tsogt",
            "Biger",
            "Tseel",
            "Bayan-Uul",
            "Bugat",
            "Tonkhil",
            "Delger",
            "Khaliun",
            "Tögrög",
            "Erdene",
            "Taishir",
            "Darvi",
            "Jargalan",
            "Chandmani",
            "Yesönbulag",
          ],
        },
        {
          name: "Говьсүмбэр",
          sumuud: ["Сүмбэр", "Баянтал сум", "Шивээговь сум"],
        },
        {
          name: "Дархан-Уул",
          sumuud: ["Дархан", "Хонгор", "Орхон", "Шарынгол"],
        },
        {
          name: "Дорноговь",
          sumuud: [
            "Замын-Үүд",
            "Хатанбулаг",
            "Алтанширээ",
            "Дэлгэрэх",
            "Даланжаргалан",
            "Иххэт",
            "Цахимхолбоо",
            "Улаанбадрах",
            "Мандах",
            "Сайхандулаан",
            "Эрдэнэ",
            "Сайншанд",
            "Өргөн",
            "Хөвсгөл",
            "Айраг",
          ],
        },
        {
          name: "Дорнод",
          sumuud: [
            "Булган",
            "Хөлөнбуйр",
            "Сэргэлэн",
            "Чойбалсан",
            "Баянтүмэн",
            "Хэрлэн*",
            "Баян-Уул",
            "Матад",
            "Чулуунхороот",
            "Баяндун",
            "Цагаан-Овоо",
            "Гурванзагал",
            "Дашбалбар",
            "Халхгол",
          ],
        },
        {
          name: "Дундговь",
          sumuud: [
            "Адаацаг",
            "Сайнцагаан",
            "Баянжаргалан",
            "Гурвансайхан",
            "Сайхан-Овоо",
            "Цагаандэлгэр",
            "Өлзийт",
            "Дэрэн",
            "Дэлгэрцогт",
            "Хулд",
            "Өндөршил",
            "Луус",
            "Дэлгэрхангай",
            "Говь-Угтаал",
            "Эрдэнэдалай",
          ],
        },
        {
          name: "Завхан",
          sumuud: [
            "Алдархаан",
            "Тэлмэн",
            "Асгат",
            "Дөрвөлжин",
            "Завханмандал",
            "Сонгино",
            "Идэр",
            "Отгон",
            "Тосонцэнгэл",
            "Баянтэс",
            "Нөмрөг",
            "Тэс",
            "Шилүүстэй",
            "Их-Уул",
            "Түдэвтэй",
            "Цагаанхайрхан",
            "Эрдэнэхайрхан",
            "Яруу",
            "Улиастай",
            "Баянхайрхан",
            "Цэцэн-Уул",
            "Сантмаргац",
            "Ургамал",
            "Цагаанчулуут",
          ],
        },
        { name: "Орхон", sumuud: ["Баян-Өндөр", "Жаргалант"] },
        {
          name: "Өвөрхангай",
          sumuud: [
            "Баруунбаян-Улаан",
            "Уянга",
            "Богд",
            "Зүүнбаян-Улаан",
            "Бүрд",
            "Сант",
            "Нарийнтээл",
            "Хайрхандулаан",
            "Арвайхээр",
            "Есөнзүйл",
            "Хужирт",
            "Өлзийт",
            "Гучин-Ус",
            "Төгрөг",
            "Тарагт",
            "Хархорин",
            "Баянгол",
            "Бат-Өлзий",
            "Баян-Өндөр",
          ],
        },
        {
          name: "Өмнөговь",
          sumuud: [
            "Манлай",
            "Сэврэй",
            "Цогтцэций",
            "Хүрмэн",
            "Цогт-Овоо",
            "Гурвантэс",
            "Номгон",
            "Ханхонгор",
            "Мандал-Овоо",
            "Даланзадгад",
            "Ноён",
            "Баян-Овоо",
            "Баяндалай",
            "Ханбогд",
            "Булган",
          ],
        },
        {
          name: "Сүхбаатар",
          sumuud: [
            "Сүхбаатар",
            "Мөнххаан",
            "Халзан",
            "Баруун-Урт",
            "Эрдэнэцагаан",
            "Түвшинширээ",
            "Түмэнцогт",
            "Баяндэлгэр",
            "Наран",
            "Асгат",
            "Дарьганга",
            "Онгон",
            "Уулбаян",
          ],
        },
        {
          name: "Сэлэнгэ",
          sumuud: [
            "Сайхан",
            "Сүхбаатар",
            "Шаамар",
            "Орхон",
            "Жавхлант",
            "Цагааннуур",
            "Баруунбүрэн",
            "Зүүнбүрэн",
            "Алтанбулаг",
            "Хушаат",
            "Түшиг",
            "Хүдэр",
            "Орхонтуул",
            "Сант",
            "Баянгол",
            "Мандал",
            "Ерөө",
          ],
        },
        {
          name: "Төв",
          sumuud: [
            "Дэлгэрхаан",
            "Борнуур",
            "Эрдэнэ",
            "Угтаалцайдам",
            "Жаргалант",
            "Баянчандмань",
            "Архуст",
            "Баянцогт",
            "Баян",
            "Өндөрширээт",
            "Цээл",
            "Сэргэлэн",
            "Сүмбэр",
            "Мөнгөнморьт",
            "Эрдэнэсант",
            "Баян-Өнжүүл",
            "Батсүмбэр",
            "Баянжаргалан",
            "Аргалант",
            "Бүрэн",
            "Баянцагаан",
            "Баянхангай",
            "Лүн",
            "Заамар",
            "Зуунмод",
            "Алтанбулаг",
            "Баяндэлгэр",
          ],
        },
        {
          name: "Увс",
          sumuud: [
            "Өлгий",
            "Завхан",
            "Бөхмөрөн",
            "Малчин",
            "Хяргас",
            "Зүүнхангай",
            "Тэс",
            "Давст",
            "Наранбулаг",
            "Улаангом",
            "Сагил",
            "Зүүнговь",
            "Тариалан",
            "Түргэн",
            "Цагаанхайрхан",
            "Ховд",
            "Өндөрхангай",
            "Өмнөговь",
            "Баруунтуруун",
          ],
        },
        {
          name: "Ховд",
          sumuud: [
            "Дуут",
            "Дөргөн",
            "Чандмань",
            "Алтай",
            "Зэрэг",
            "Мөст",
            "Мөнххайрхан",
            "Манхан",
            "Жаргалант",
            "Дарви",
            "Ховд",
            "Булган",
            "Үенч",
            "Эрдэнэбүрэн",
            "Цэцэг",
            "Мянгад",
            "Буянт",
          ],
        },
        {
          name: "Хөвсгөл",
          sumuud: [
            "Цэцэрлэг",
            "Рэнчинлхүмбэ",
            "Тосонцэнгэл",
            "Алаг-Эрдэнэ",
            "Рашаант",
            "Улаан-Уул",
            "Мөрөн",
            "Түнэл",
            "Галт",
            "Ханх",
            "Цагаан-Үүр",
            "Тариалан",
            "Цагааннуур",
            "Бүрэнтогтох",
            "Арбулаг",
            "Чандмань-Өндөр",
            "Баянзүрх",
            "Эрдэнэбулган",
            "Цагаан-Уул",
            "Төмөрбулаг",
            "Хатгал",
            "Их-Уул",
            "Жаргалант",
            "Шинэ-Идэр",
          ],
        },
        {
          name: "Хэнтий",
          sumuud: [
            "Баян-Адрага",
            "Дэлгэрхаан",
            "Дархан",
            "Өмнөдэлгэр",
            "Мөрөн",
            "Галшар",
            "Батноров",
            "Баянхутаг",
            "Хэрлэн",
            "Баянмөнх",
            "Дадал",
            "Батширээт",
            "Жаргалтхаан",
            "Өлзийт",
            "Норовлин",
            "Цэнхэрмандал",
            "Биндэр",
            "Баян-Овоо",
          ],
        },
      ],
    };
  },
  created() {
    db.collection("countries/Mongolia/cities")
      .orderBy("index", "asc")
      .onSnapshot((querySnapshot) => {
        this.aimguud = [];
        querySnapshot.forEach((doc) => {
          console.log(doc.id, " x=> ", doc.data());
          let city = doc.data();
          city.id = doc.id;
          city.ref = doc.ref;

          this.aimguud.push(city);
        });
      });
  },
  methods: {
    _goDetail(id) {
      this.$router.push({
        name: "RestaurantDetail",
        params: { restaurantId: id },
      });
    },
    _inv() {
      this.editImage = !this.editImage;
    },
    async addItem() {
      if (this.name && this.price) {
        await db.collection("aimguud/").add({
          name: this.name,
          price: this.price,
          createdAt: new Date(),
          rating: null,
        });
        this.name = null;
        this.price = null;
      }
    },
    async _initAimguud() {
      var counter = 0;
      for (const city of this.cities2) {
        counter++;
        console.log(city.name);
        const cityCreated = await db
          .collection("/countries/Mongolia/cities")
          .add({ name: city.name, index: counter });
        for (const childx of city.sumuud) {
          cityCreated.collection("children").doc().set({ name: childx });
        }
      }
    },
  },
  firestore: {
    Aimguud: db.collection("aimguud").orderBy("createdAt", "asc"),
  },
};
</script>
<style>
.p-absolute {
  position: absolute !important;
}
.top-0 {
  top: 0 !important;
}
.text-18 {
  font-size: 20px !important;
}

.f-600 {
  font-weight: 400;
}
.text-14,
h6 {
  font-size: 14px;
}
</style>